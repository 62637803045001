var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"segmented",attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('reporting.title')))])])],1)],1),_c('v-card-text',[_c('v-list',{attrs:{"flat":"","color":"white"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h3',[_vm._v("Status Reporting")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('reporting.statusReportInfo')))])],1)],1),(_vm.isProcessTypeAvailable(_vm.processTypes.MAFEG))?_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.MAFEG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: MAFEG Status")])],1)],1):_vm._e(),(_vm.isProcessTypeAvailable(_vm.processTypes.EHG))?_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.EHG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: EHG Status")])],1)],1):_vm._e(),(_vm.isProcessTypeAvailable(_vm.processTypes.PDP))?_c('v-list-item',{attrs:{"to":{
          name: 'pdp-report',
          params: { reportType: 2 }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: PDP Status")])],1)],1):_vm._e(),(_vm.isProcessTypeAvailable(_vm.processTypes.PDC))?_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.PDC
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: PDC Status")])],1)],1):_vm._e(),(_vm.isProcessTypeAvailable(_vm.processTypes.EDC))?_c('v-list-item',{attrs:{"to":{
          name: 'status-report',
          params: {
            reportType: 1,
            processType: _vm.processTypes.EDC
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: EDC Status")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":"","to":{
          name: 'pdp-report',
          params: { reportType: 999 }
        }}},[(false)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Beförderungsprozess")])],1):_vm._e()],1)],1),_c('v-list',{attrs:{"flat":"","color":"white mt-4"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('h3',[_vm._v("Erweitertes Reporting")])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t('reporting.advancedReportInfo')))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'career-development-report'
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Angestrebte Entwicklung")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf die angestrebte Entwicklung (hierarchische / laterale Entwicklung) aus den freigegebenen MAFEG-Dokumentationen. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'development-measures-report',
          params: {
            reportType: 3,
            processType: _vm.processTypes.MAFEG
          }
        }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Entwicklungsmaßnahmen")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf die gewünschten Entwicklungsmaßnahmen (z. B. Trainings, Skill-Workshops) aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1),(false)?[_c('v-list-item',{attrs:{"to":{
            name: 'development-measures-report',
            params: {
              reportType: 5,
              processType: _vm.processTypes.MAFEG
            }
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Performance in der Expertenrolle")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Experten und Expertinnen in Bezug auf ihre Performance in der Expertenrolle aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'development-measures-report',
            params: {
              reportType: 6,
              processType: _vm.processTypes.MAFEG
            }
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Auswertung der Testrolle")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf ihre Testrolle aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'development-measures-report',
            params: {
              reportType: 7,
              processType: _vm.processTypes.MAFEG
            }
          }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Report: Auswertung der Sonderrolle")]),_c('v-list-item-subtitle',[_vm._v(" In diesem Report findest du eine Übersicht über die Eingaben deiner direkten und indirekten Mitarbeitenden in Bezug auf ihre ausgeübte Sonderrolle aus der freigegebenen MAFEG-Dokumentation. ")])],1)],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }